import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import VueLazyload from 'vue-lazyload'
import router from './router'
// 引入字体图标方法
import * as ElementPlusIconsVue from "@element-plus/icons-vue";


const app = createApp(App);
// 字体图标引入（全局注册）
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(router).use(ElementPlus).use(VueLazyload).mount("#app");

app.use(VueLazyload,{
  preLoad:1.3,
  attempt:1
})
