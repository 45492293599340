import {createRouter, createWebHistory} from 'vue-router'


const routes = [
    {
        path: '/',
        name: 'home', component: () => {

            if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return import('../views/mobile/HomePage.vue')
            } else {
                return import('../views/pc/HomePage.vue')
            }
        },
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
