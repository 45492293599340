<template>
    <router-view></router-view>
</template>

<script setup>

</script>

<style>
#app{
    width: 100vw;

}
html{
    width: 100vw;
    margin:0;
    padding:0;
}
body{
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    margin:0;
    padding:0;
}
</style>
